import React from 'react';
import { compose } from 'recompose';

import { withAuthorization, withEmailVerification } from '../Session';
import {withFirebase} from "../Firebase";
import PasswordChangeForm from '../PasswordChange';
import * as ROUTES from '../../constants/routes';

import AccountDeleteButton from "./AccountDeleteButton";

// Bootstrap
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import ListGroup from "react-bootstrap/ListGroup";

class AccountPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            authUser: JSON.parse(localStorage.getItem('authUser'))
        };
    }

    render() {
        const { authUser } = this.state;

        return (
            <div>
                <Nav defaultActiveKey="/home" as="ul" className="pageTitleDiv">
                    <Nav.Item as="li">
                        <Nav.Link href="/home" className="text-white">
                            <FontAwesomeIcon icon={faChevronLeft}/>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li" className="mx-auto pr-5">
                        <h2 className="mx-auto mb-0">Mein Profil</h2>
                    </Nav.Item>
                </Nav>

                <Container>
                    <div className="mx-3 mb-3">
                        <h2>Übersicht</h2>
                        <hr/>
                        <div className="col-sm-10 col-md-8 col-lg-6">
                            <ListGroup variant="flush">
                                <ListGroup.Item>Vorname: &emsp; &nbsp; &nbsp; {authUser.firstName}</ListGroup.Item>
                                <ListGroup.Item>Nachname: &emsp; {authUser.lastName}</ListGroup.Item>
                                <ListGroup.Item>Email: &emsp; &emsp; &emsp; {authUser.email}</ListGroup.Item>
                                <ListGroup.Item>Kurse: &emsp; &emsp; &nbsp; &nbsp; &nbsp;
                                    { authUser.course_1 && <a className="d-inline-flex mr-3" href={ROUTES.COURSE_1_CONTENT}>{authUser.course_1.name}</a> }
                                    { authUser.course_2 && <a className="d-inline-flex mr-3" href={ROUTES.COURSE_2_CONTENT}>{authUser.course_2.name}</a> }
                                    { authUser.course_3 && <a className="d-inline-flex mr-3" href={ROUTES.COURSE_3_CONTENT}>{authUser.course_3.name}</a> }
                                </ListGroup.Item>
                            </ListGroup>
                        </div>
                    </div>

                    <div className="d-inline-flex align-items-start my-5">
                            <PasswordChangeForm />
                    </div>

                    <div className="mx-3 mt-3">
                        <h2>Accont löschen</h2>
                        <hr/>
                        <p>Möchstest du deinen Account löschen? <br/>Dies kann nicht rückgängig gemacht werden.</p>
                        <AccountDeleteButton/>
                    </div>

                </Container>
            </div>
        )
    }

}

const condition = authUser => !!authUser;

export default compose(
    withFirebase,
    withEmailVerification,
    withAuthorization(condition),
)(AccountPage);
