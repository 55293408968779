import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import "firebase/performance";
import 'firebase/storage';

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
};

class Firebase {
    constructor() {
        app.initializeApp(config);

        /* Performance */
        this.perf = app.performance();

        /* Helper */
        this.fieldValue = app.firestore.FieldValue;
        this.emailAuthProvider = app.auth.EmailAuthProvider;

        /* Firebase APIs */
        this.auth = app.auth();
        this.auth.languageCode = 'de';
        this.db = app.firestore();
        this.storage = app.storage();

        /* Social Sign In Method Provider */
        this.googleProvider = new app.auth.GoogleAuthProvider();
        this.facebookProvider = new app.auth.FacebookAuthProvider();
        this.googleProvider.addScope('email');
    }

    // *** Auth API ***
    doCreateUserWithEmailAndPassword = (email, password) =>
        this.auth.createUserWithEmailAndPassword(email, password);

    doSignInWithEmailAndPassword = (email, password) =>
        this.auth.signInWithEmailAndPassword(email, password);

    doSignOut = () => this.auth.signOut();

    doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

    doPasswordUpdate = password =>
        this.auth.currentUser.updatePassword(password);

    doSendEmailVerification = () =>
        this.auth.currentUser.sendEmailVerification({
            url: 'https://academy.lisastraub.com/welcome'
            /* url: 'http://localhost:3000/welcome'*/
        });

    doSignInWithGoogle = () =>
        this.auth.signInWithPopup(this.googleProvider);

    doSignInWithFacebook = () =>
        this.auth.signInWithPopup(this.facebookProvider);

    // *** Merge Auth and DB User API *** //
    onAuthUserListener = (next, fallback) =>
        this.auth.onAuthStateChanged(authUser => {
            if (authUser) {
                this.user(authUser.uid)
                    .get()
                    .then(snapshot => {
                        const dbUser = snapshot.data();

                        // default empty roles
                        if (!dbUser.roles) {
                            dbUser.roles = {};
                        }
                        // merge auth and db user

                        this.db.collection('users').doc(authUser.uid)
                            .update({emailVerified: authUser.emailVerified})
                            .then();

                        authUser = {
                            uid: authUser.uid,
                            email: authUser.email,
                            firstName: authUser.firstName,
                            lastName: authUser.lastName,
                            createdAt: authUser.createdAt,
                            emailVerified: authUser.emailVerified,
                            providerData: authUser.providerData,
                            course_1: authUser.course_1,
                            course_2: authUser.course_2,
                            course_3: authUser.course_3,
                            ...dbUser,
                        };

                        next(authUser);
                    });
            } else { fallback(); }
        });

    doDeleteUser = () => {
        return this.auth.currentUser.delete();
    };

    // *** User API ***
    user = uid => this.db.doc(`users/${uid}`);
    users = () => this.db.collection('users');

    // Courses API
    addCourseOne = (authUser) => {
        let courseRef = this.db.collection('users').doc(authUser);
        return courseRef.update({ course_1: {
            name: 'Trennung - Neubeginn',
            paid: true,
            m1: 'open', m2: 'open', m3: 'open', m4: 'open', m5: 'open', m6: 'open',
            s1: 'open',
        } });
    };

    addCourseTwo = (authUser) => {
        let courseRef = this.db.collection('users').doc(authUser);
        return courseRef.update({ course_2: {
                name: 'Fülle pur Okt 2021',
                paid: true,
                date: 'Okt 2021'
            } });
    };

    addCourseThree = (authUser) => {
        let courseRef = this.db.collection('users').doc(authUser);
        return courseRef.update({ course_3: {
                name: 'Fülle pur Jan 2022',
                paid: true,
                date: 'Jan 2022'
            } });
    };

    changeCourseOneProgress = (authUser, module) =>
        this.db.collection('users').doc(authUser).get().then((doc) => {
                let moduleName = `course_1.${module}`;

                if ((doc.data())['course_1'][module] === "open") {
                    this.db.collection('users').doc(authUser).update({ [moduleName] : 'done' });
                    return true;
                } else {
                    this.db.collection('users').doc(authUser).update({ [moduleName] : 'open'});
                    return false;
                }
            });


    calculateCourseOneProgress = (authUser) => {
        return this.db
            .collection('users')
            .doc(authUser)
            .get()
            .then(doc => {
                let course_1 = (doc.data())['course_1'];
                let finished_modules = 0;


                for (let value of Object.values(course_1)) {
                    if (value === 'done') {
                        finished_modules++;
                    }
                }
                return (Math.round(100/7*finished_modules));
            });
    };

}

export default Firebase;