import React from 'react';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import SignOutButton from '../AuthProcess/SignOut/SignOut';
import { AuthUserContext } from '../Session';

import './navigation.css';
import logo from './logo_newsletter.png';

// Bootstrap & Fontawesome
import Navbar from "react-bootstrap/Navbar";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Nav from "react-bootstrap/Nav";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { faUser } from "@fortawesome/free-solid-svg-icons";

library.add(faFacebookF, faUser);

const Navigation = () => (
    <div>
        <AuthUserContext.Consumer>
            {authUser =>
                authUser ? <NavigationAuth authUser={authUser}/> : <NavigationNonAuth />
            }
        </AuthUserContext.Consumer>
    </div>
);

class NavigationAuth extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isDesktop: false //This is where I am having problems
        };

        this.updatePredicate = this.updatePredicate.bind(this);
    }
    componentDidMount() {
        this.updatePredicate();
        window.addEventListener("resize", this.updatePredicate);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updatePredicate);
    }

    updatePredicate() {
        this.setState({ isDesktop: window.innerWidth > 500 });
    }
    renderButtonBig() {
        return (
            <div className="d-inline-flex align-items-baseline">
                <FontAwesomeIcon className="fontAwesomeIcon text-white " icon={['fas', 'user']}/>
                <p className="mx-3 mb-0">{this.props.authUser.firstName}</p>
            </div>
        )
    }

    renderButtonSmall() {
            return (
                <div className="d-inline-flex align-items-baseline">
                    <FontAwesomeIcon className="fontAwesomeIcon text-white " icon={['fas', 'user']} />
                </div>
            )
    }

    render() {
        const isDesktop = this.state.isDesktop;

        return (
            <Navbar className="Navbar" fixed="top">
                <Navbar.Brand href={ROUTES.HOME} >
                    <Image src={logo} alt="logo" className="logo" />
                    <p className="brand">Academy</p>
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="justify-content-end">
                    <Navbar.Text>
                        {isDesktop ? (
                            <DropdownButton
                                alignRight
                                title={this.renderButtonBig()}
                                id="dropdown-menu-align-right" >
                                {!!this.props.authUser.roles[ROLES.ADMIN] && (
                                    <li>
                                        <Dropdown.Item eventKey="1" href={ROUTES.ADMIN}>Admin</Dropdown.Item>
                                        <Dropdown.Divider />
                                    </li>
                                )}
                                <Dropdown.Item eventKey="1" href={ROUTES.HOME}>Kursübersicht</Dropdown.Item>
                                <Dropdown.Item eventKey="1" href={ROUTES.ACCOUNT}>Profil</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item eventKey="3"> <SignOutButton /> </Dropdown.Item>
                            </DropdownButton>
                        ) : (
                            <DropdownButton
                                alignRight
                                title={this.renderButtonSmall()}
                                id="dropdown-menu-align-right" >
                                {!!this.props.authUser.roles[ROLES.ADMIN] && (
                                    <li>
                                        <Dropdown.Item eventKey="1" href={ROUTES.ADMIN}>Admin</Dropdown.Item>
                                        <Dropdown.Divider />
                                    </li>
                                )}
                                <Dropdown.Item eventKey="1" href={ROUTES.HOME}>Kursübersicht</Dropdown.Item>
                                <Dropdown.Item eventKey="1" href={ROUTES.ACCOUNT}>Profil</Dropdown.Item>
                                <Dropdown.Divider />
                                <Dropdown.Item eventKey="3"> <SignOutButton /> </Dropdown.Item>
                            </DropdownButton>
                        )}

                    </Navbar.Text>
                </Navbar.Collapse>
            </Navbar>
        )
    }

}

class NavigationNonAuth extends React.Component {
    render() {
        return (
            <Navbar expand="sm" className="Navbar" fixed="top">
                <Navbar.Brand href={ROUTES.LANDING} className="col-8 pl-0">
                    <Image src={logo} alt="logo" className="logo" />
                    <p className="brand">Academy</p>
                </Navbar.Brand>
                <Navbar.Toggle />
                <Navbar.Collapse className="flex-row-reverse">
                    <Nav className="text-center d-inline d-md-flex">
                        <Nav.Link className="signInButton" href={ROUTES.SIGN_IN}>Anmelden</Nav.Link>
                        <Button href={ROUTES.SIGN_UP} className="rounded-pill mx-3 d-block">Registrieren</Button>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        )
    }
}

export default Navigation;