import React from 'react';
import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

const needsEmailVerification = authUser =>
    authUser &&
    !authUser.emailVerified &&
    authUser.providerData
        .map(provider => provider.providerId)
        .includes('password');

const withEmailVerification = Component => {
    class WithEmailVerification extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                isSent: false
            };
        }

        onSendEmailVerification = () => {
            this.props.firebase.doSendEmailVerification()
                .then(() => this.setState({ isSent: true }));
        };

        render() {
            return (
                <AuthUserContext.Consumer>
                    {authUser =>
                        needsEmailVerification(authUser) ? (
                            <Container>
                                <h2>Email bestätigen</h2>
                                <hr className="mb-4"/>
                                <div>
                                    {this.state.isSent ? (
                                        <p>
                                            Bestätigungsmail gesendet.
                                            <br/><br/>
                                            Laden Sie diese Seite neu sobald Sie ihre Mail bestätigt haben.
                                        </p>
                                    ) : (
                                        <p>
                                            Bestätigen Sie ihre E-Mail: Schauen Sie auch im Spam-Ordner
                                            für eine Bestätigungsmail oder senden Sie diese erneut.
                                        </p>
                                    )}
                                    <Button
                                        type="button" className="my-3"
                                        onClick={this.onSendEmailVerification}
                                        disabled={this.state.isSent}>
                                        Bestätigungsmail erneut senden
                                    </Button>
                                </div>
                            </Container>

                        ) : (
                            <Component {...this.props} />
                        )
                    }
                </AuthUserContext.Consumer>
            );
        }
    }
    return withFirebase(WithEmailVerification);
};

export default withEmailVerification;
