import React from 'react';
import * as ROUTES from "../../constants/routes";
import image_course_3 from '../Courses/Course_3/image_course_3.jpeg';

import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons/faChevronCircleRight";
import { faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons/faMapMarkerAlt";
import { faDollarSign } from "@fortawesome/free-solid-svg-icons/faDollarSign";
import { faCalendarDay } from "@fortawesome/free-solid-svg-icons/faCalendarDay";
import { faClock } from "@fortawesome/free-solid-svg-icons/faClock";

const LandingPage = () => (
    <div>
        <Container>
            <Row>
                <div className="col-5">
                    <Image id="landingImage" src={image_course_3} fluid/>
                </div>
                <div className="d-flex flex-column col-7 justify-content-center">
                    <p id="landingSupTitle">2022 Onlinekurs</p>
                    <h1 id="landingTitle">Fülle pur</h1>
                    <p id="landingSubTitle">Wir rollen 2022 den roten Teppich aus und manifestieren Fülle auf allen Ebenen.</p>
                    <a id="landingLink" href="https://lisastraub.com/seminare/fuelle-pur">Weitere Informationen</a>
                </div>
            </Row>
        </Container>

        <Container id="beige">
            <h2 className="text-center pb-2">Infos zum Kurs</h2>

            <div className="row text-center py-5">
                <div className="col-lg-3 col-6">
                    <FontAwesomeIcon className="pl-2 my-4" size="2x" icon={faMapMarkerAlt}/>
                    <p>Online via Zoom
                        <br/><br/>Zum Kurs gibt es eine freiwillige Facebook-Gruppe.
                    </p>
                </div>
                <div className="col-lg-3 col-6">
                    <FontAwesomeIcon className="pl-2 my-4" size="2x" icon={faDollarSign}/>
                    <p>Fr 220.-</p>
                </div>
                <div className="col-lg-3 col-6">
                    <FontAwesomeIcon className="pl-2 my-4" size="2x" icon={faCalendarDay}/>
                    <p>17. / 24. / 31. Januar
                        <br/>7. / 14. / 21. Februar</p>
                </div>
                <div className="col-lg-3 col-6">
                    <FontAwesomeIcon className="pl-2 my-4" size="2x" icon={faClock}/>
                    <i className="far fa-clock"></i>
                    <p>19 bis 21 Uhr
                        <br/>mit anschliessender Fernheilung
                    </p>
                </div>
            </div>



        </Container>

        <Container className="bigNavContentWrapper d-flex justify-content-center">
            <Button className="rounded-pill px-3 py-2" href={ROUTES.SIGN_IN}>
                Jetzt loslegen
                <FontAwesomeIcon className="pl-2" size="lg" icon={faChevronCircleRight}/>
            </Button>
        </Container>
    </div>
);

export default LandingPage;