import React from 'react';
import { compose } from "recompose";
import { withAuthorization, withEmailVerification } from '../Session';
import { withFirebase } from '../Firebase';

import DashboardCardCourse3 from "./DashboardCardCourse3";

// Bootstrap
import Container from "react-bootstrap/Container";

import './dashboard.css';
import Nav from "react-bootstrap/Nav";

class Dashboard extends React.Component {

    render() {
        return (
            <div>
                <Nav defaultActiveKey="/home" as="ul" className="pageTitleDiv">
                    <Nav.Item as="li" className="mx-auto">
                        <h2 className="mb-0">Kursübersicht</h2>
                    </Nav.Item>
                </Nav>

                <Container>
                    <div className='rowC'>
                        <DashboardCardCourse3 />
                    </div>
                </Container>
            </div>
        )
    }
}

const condition = authUser => !!authUser;

export default compose(
    withFirebase,
    withEmailVerification,
    withAuthorization(condition),
)(Dashboard);