import React, {Component} from 'react';
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import * as ROUTES from "../../../constants/routes";

class SignUpWelcome extends Component {
    render() {
        return (
            <Container>
                <p>Vielen Dank für deine Anmeldung!</p>

                <Button href={ROUTES.HOME}>
                    Zu deiner Kursübersicht
                </Button>

            </Container>
        );
    }
}

export default SignUpWelcome;