import React from "react";
import {Link} from "react-router-dom";
import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

import Container from "react-bootstrap/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import {compose} from "recompose";
import {withAuthorization, withEmailVerification} from "../Session";
import * as ROLES from "../../constants/roles";


class UserListBase extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            users: [],
        };
    }

    componentDidMount() {
        this.setState({ loading: true });
        this.unsubscribe = this.props.firebase
            .users()
            .orderBy('createdAt', 'desc')
            .onSnapshot(snapshot => {
                let users = [];
                snapshot.forEach(doc =>
                    users.push({ ...doc.data(), uid: doc.id }),
                );
                this.setState({
                    users,
                    loading: false,
                });
            });
    }

    componentWillUnmount() {
        this.unsubscribe();
    }

    render() {
        const { users, loading } = this.state;
        return (
            <Container>

                {loading ? <div className="ml-3">Loading ...</div> : (
                    <table className="table table-hover pt-5">
                        <thead>
                        <tr>
                            <th scope="col">Datum</th>
                            <th scope="col">Vorname</th>
                            <th scope="col">Nachname</th>
                            <th scope="col">Email</th>
                            <th scope="col">Details</th>
                        </tr>
                        </thead>
                        <tbody>
                        {users.map(user => (
                            <tr key={user.uid} >
                                <td >{user.createdAt.split("T", 1)}</td>
                                <td >{user.firstName}</td>
                                <td >{user.lastName}</td>
                                <td >
                                    {user.emailVerified ?
                                        <FontAwesomeIcon className="mr-3" icon={faCheckCircle} /> :
                                        <FontAwesomeIcon className="mr-3" icon={faTimesCircle} /> }
                                        {user.email}</td>
                                <td >
                                    <Link to={{ pathname: `${ROUTES.ADMIN}/${user.uid}`, state: { user }, }}>Details</Link>
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                )}
            </Container>
        );
    }
}

const condition = authUser =>
    authUser && !!authUser.roles[ROLES.ADMIN];


export default compose(
    withEmailVerification,
    withAuthorization(condition),
    withFirebase
)(UserListBase);