import React from "react";
import Container from "react-bootstrap/Container";
import DropdownButton from "react-bootstrap/DropdownButton";
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import { withFirebase } from "../Firebase";


import {Link} from "react-router-dom";
import * as ROUTES from "../../constants/routes";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheckCircle} from "@fortawesome/free-solid-svg-icons/faCheckCircle";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons/faTimesCircle";
import * as ROLES from "../../constants/roles";
import {compose} from "recompose";
import {withAuthorization, withEmailVerification} from "../Session";

class UserItemBase extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            date: null,
            loading: false,
            user: null,
            ...props.location.state,
        };

    }

    componentDidMount() {
        if (this.state.user) {
            return;
        }
        this.setState({ loading: true });
        this.unsubscribe = this.props.firebase
            .user(this.props.match.params.id)
            .onSnapshot(snapshot => {
                this.setState({
                    user: snapshot.data(),
                    loading: false,
                });
            });
    }

    componentWillUnmount() {
        this.unsubscribe && this.unsubscribe();
    }

    onSendPasswordResetEmail = () => {
        this.props.firebase.doPasswordReset(this.state.user.email);
    };

    onAddCourseOneManually = (userID) => {
        this.props.firebase.addCourseOne(userID);
        this.props.history.push(ROUTES.ADMIN);
    };

    onAddSessionManually = (userID) => {
        const currentModule = "s1";
        this.props.firebase
            .changeCourseOneProgress(userID, currentModule);
        this.props.history.push(ROUTES.ADMIN);
    };

    onAddCourseTwoManually = (userID) => {
        this.props.firebase.addCourseTwo(userID);
        this.props.history.push(ROUTES.ADMIN);
    };

    onAddCourseThreeManually = (userID) => {
        this.props.firebase.addCourseThree(userID);
        this.props.history.push(ROUTES.ADMIN);
    };


    render() {
        const { user, loading } = this.state;

        return (
            <Container>
                <h3 className="py-2">{user.firstName} {user.lastName}</h3>
                <Link to={ROUTES.ADMIN}> &lt; zurück</Link>
                {loading && <div>Loading ...</div>}
                {user && (
                    <div>
                        <table className="table mt-3">
                            <thead>
                                <tr className="d-flex">
                                    <th className="col-1" scope="col">&nbsp;</th>
                                    <th className="col-4" scope="col">Information</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="d-flex">
                                    <th className="col-1" scope="row">ID</th>
                                    <td className="col-4">{user.uid}</td>
                                </tr>
                                <tr className="d-flex">
                                    <th className="col-1" scope="row">Datum</th>
                                    <td className="col-4">{user.createdAt.split("T", 1)}</td>
                                </tr>
                                <tr className="d-flex">
                                    <th className="col-1" scope="row">Email</th>
                                    <td className="col-4">
                                        {user.email}
                                        {user.emailVerified ?
                                            <FontAwesomeIcon className="ml-3" icon={faCheckCircle} /> :
                                            <FontAwesomeIcon className="ml-3" icon={faTimesCircle} /> }
                                    </td>
                                </tr>
                                <tr className="d-flex">
                                    <th className="col-1" scope="row">Kurse</th>
                                    <td className="col-4">
                                        { !user.course_1 && !user.course_2 && !user.course_3 && "Kein Kurs gebucht" }
                                        { user.course_1 && "Trennung - Neubeginn"}<br/>
                                        { user.course_2 && "Fülle pur Okt 2021"}<br/>
                                        { user.course_3 && "Fülle pur Jan 2022"}
                                    </td>
                                </tr>

                                { (!user.course_1 || !user.course_2 || !user.course_3) &&
                                <DropdownButton variant="link" id="dropdown-basic-button" title="Kurs hinzufügen" className="mb-4">
                                    {!user.course_1 && <Dropdown.Item onClick={() => this.onAddCourseOneManually(user.uid)} >Trennung - Neubeginn</Dropdown.Item> }
                                    {!user.course_2 && <Dropdown.Item onClick={() => this.onAddCourseTwoManually(user.uid)} >Fülle pur Okt 21</Dropdown.Item> }
                                    {!user.course_3 && <Dropdown.Item onClick={() => this.onAddCourseThreeManually(user.uid)} >Fülle pur Jan 22</Dropdown.Item> }

                                </DropdownButton>
                                }

                                { user.course_1 && (
                                <tr className="d-flex">
                                    <th className="col-1" scope="row">Session T/N</th>
                                    <td className="col-4"> { user.course_1.s1 === "open" ?
                                        <div>
                                            <p>Session nicht gehabt</p>
                                            <Button onClick={() => this.onAddSessionManually(user.uid)}
                                                            variant="link" id="dropdown-basic-button"
                                                            title="Session hinzufügen" className="mb-4">
                                                Session durchgeführt
                                            </Button>
                                        </div> : "Session gehabt"}</td>
                                </tr> )}
                            </tbody>
                        </table>



                        <Button
                            type="button"
                            className="mt-3"
                            onClick={this.onSendPasswordResetEmail} >
                            Passwort manuell zurücksetzen
                        </Button>

                    </div>
                )}
            </Container>
        );
    }
}

const condition = authUser =>
    authUser && !!authUser.roles[ROLES.ADMIN];


export default compose(
    withEmailVerification,
    withAuthorization(condition),
    withFirebase
)(UserItemBase);