import React, {Component} from "react";
import * as ROUTES from "../../constants/routes";
import Button from "react-bootstrap/Button";
import {compose} from "recompose";
import {withRouter} from "react-router-dom";
import {withFirebase} from "../Firebase";

class SignInFacebookBase extends Component {
    constructor(props) {
        super(props);
        this.state = { error: null };
    }
    onSubmit = event => {
        const createdAt = new Date().toISOString();

        this.props.firebase
            .doSignInWithFacebook()
            .then(socialAuthUser => {
                // Create a user in Firestore
                return this.props.firebase
                    .user(socialAuthUser.user.uid)
                    .set({
                        firstName: socialAuthUser.user.displayName.split(" ")[0],
                        lastName: socialAuthUser.user.displayName.split(" ")[1],
                        email: socialAuthUser.user.email,
                        emailVerified: socialAuthUser.user.emailVerified,
                        createdAt: createdAt,
                        roles: {}
                    });
            })
            .then(socialAuthUser => {
                this.setState({ error: null });
                this.props.history.push(ROUTES.HOME);
            })
            .catch(error => {
                this.setState({ error });
            });
        event.preventDefault();
    };
    render() {
        const { error } = this.state;
        return (
            <form onSubmit={this.onSubmit}>
                <Button type="submit" className="rounded-pill" variant="outline-info" >mit Facebook</Button>
                {error && <p>{error.message}</p>}
            </form>
        );
    }
}

const SignInFacebook = compose(
    withRouter,
    withFirebase,
)(SignInFacebookBase);

export default SignInFacebook;
