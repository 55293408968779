export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';

export const PASSWORD_FORGET = '/pw-forget';

export const HOME = '/home';
export const ACCOUNT = '/account';
export const ADMIN = '/admin';
export const ADMIN_DETAILS = '/admin/:id';

export const WELCOME = '/welcome';



export const COURSE_1_OVERVIEW = '/course_1/overview';
export const COURSE_1_CONTENT = '/course_1/content';
export const COURSE_1_MODULE_1 = '/course_1/module_1';
export const COURSE_1_MODULE_2 = '/course_1/module_2';
export const COURSE_1_MODULE_3 = '/course_1/module_3';
export const COURSE_1_SESSION_1 = '/course_1/session_1';
export const COURSE_1_MODULE_4 = '/course_1/module_4';
export const COURSE_1_MODULE_5 = '/course_1/module_5';
export const COURSE_1_MODULE_6 = '/course_1/module_6';
export const COURSE_1_THANKYOU = '/course_1/thanks';


export const COURSE_2_OVERVIEW = '/course_2/overview';
export const COURSE_2_CONTENT = '/course_2/content';

export const COURSE_3_OVERVIEW = '/course_3/overview';
export const COURSE_3_CONTENT = '/course_3/content';
