import React from 'react';
import PayPalButton from './PayPalButton';
import * as ROUTES from "../../constants/routes";
import { withFirebase } from "../Firebase";
import { AuthUserContext } from "../Session";
import { withRouter } from 'react-router-dom';

const CLIENT = {
    sandbox: process.env.REACT_APP_PAYPAL_CLIENT_ID_SANDBOX,
    production: process.env.REACT_APP_PAYPAL_CLIENT_ID_PRODUCTION,
};

const ENV = process.env.REACT_APP_NODE_ENV === 'production'
    ? 'production'
    : 'sandbox';

class PayPalButtonCourse1 extends React.Component {

    buyCourseOne = (authUser) => {
        this.props.firebase
            .addCourseOne(authUser)
            .then(() => {
                this.props.history.push(ROUTES.COURSE_1_THANKYOU);
            })
            .catch(error => {
                console.error(error);
            });
    };

    render() {

        const onError = (error) =>
            console.log('Erroneous payment OR failed to load script!', error);
        const onCancel = (data) =>
            console.log('Cancelled payment!', data);
        return (
            <AuthUserContext.Consumer>
                {authUser => (
                    <PayPalButton
                        client={CLIENT}
                        env={ENV}
                        commit={true}
                        currency={'CHF'}
                        total={149}
                        onSuccess={() => this.buyCourseOne(authUser.uid)}
                        onError={onError}
                        onCancel={onCancel}
                    />
                )}
            </AuthUserContext.Consumer>
        );
    }
}

export default withRouter(withFirebase(PayPalButtonCourse1));