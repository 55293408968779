import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {compose} from "recompose";
import {withFirebase} from "../../../Firebase";
import {
    withAuthentication,
    withAuthorization,
    withCourseVerificationOne,
    withEmailVerification
} from "../../../Session";
import * as ROUTES from '../../../../constants/routes';

// Bootstrap & Fontawesome
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import {faCheckSquare} from "@fortawesome/free-solid-svg-icons/faCheckSquare";
import {faSquare} from "@fortawesome/free-solid-svg-icons/faSquare";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons/faFilePdf";
import { far } from '@fortawesome/free-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core'
import Button from "react-bootstrap/Button";

library.add(far, faCheckSquare, faSquare, faFilePdf);


class Course_1_Session_1 extends Component {

    render() {
        return (
            <div>
                <Nav defaultActiveKey="/course_1/content" as="ul" className="pageTitleDiv">
                    <Nav.Item as="li">
                        <Nav.Link href="/course_1/content" className="text-white">
                            <FontAwesomeIcon icon={faChevronLeft}/>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li" className="mx-auto pr-5">
                        <h2 className="mx-auto mb-0">Einzelsitzung</h2>
                    </Nav.Item>
                </Nav>

                <Container className="col-9">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. </p>

                    <Row className="col-10 offset-1 my-5">
                            <iframe src="https://lisabethstraub1.youcanbook.me/?noframe=true&skipHeaderFooter=true"
                                    id="ycbmiframelisabethstraub1" title="Booking"
                                    frameBorder="0" allowTransparency="true"/>
                    </Row>


                    <h6>Wenn du deine Sitzung hattest, kannst du hier weitermachen:</h6>

                    <Button className="d-flex rounded-pill">
                        <Link to={ROUTES.COURSE_1_MODULE_4} className="text-white">Zum nächsten Modul</Link>
                    </Button>

                </Container>
            </div>
        );
    }
}

const condition = authUser => !!authUser;

export default compose(
    withFirebase,
    withEmailVerification,
    withAuthentication,
    withAuthorization(condition),
    withCourseVerificationOne,
)(Course_1_Session_1);