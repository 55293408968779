import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {compose} from "recompose";
import {withFirebase} from "../../../Firebase";
import {
    withAuthentication,
    withAuthorization,
    withCourseVerificationOne,
    withEmailVerification
} from "../../../Session";
import * as ROUTES from '../../../../constants/routes';

// Bootstrap & Fontawesome
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons/faChevronLeft";
import {faCheckSquare} from "@fortawesome/free-solid-svg-icons/faCheckSquare";
import {faSquare} from "@fortawesome/free-solid-svg-icons/faSquare";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons/faFilePdf";
import { far } from '@fortawesome/free-regular-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core'
import Button from "react-bootstrap/Button";

library.add(far, faCheckSquare, faSquare, faFilePdf);


class Course_1_Module_4 extends Component {
    constructor(props) {
        super(props);

        this.state = {
            authUser: JSON.parse(localStorage.getItem('authUser')),
            m1Done: (Object.values(JSON.parse(localStorage.getItem('authUser')).course_1["m4"]).toString().split(",").join("") === "done")
        };
    }

    finishModule = () => {
        const currentModule = "m4";
        this.props.firebase
            .changeCourseOneProgress(this.state.authUser.uid, currentModule)
            .then((output) => {
                this.setState({ m1Done: output});
            });
    };

    render() {
        return (
            <div>
                <Nav defaultActiveKey="/course_1/content" as="ul" className="pageTitleDiv">
                    <Nav.Item as="li">
                        <Nav.Link href="/course_1/content" className="text-white">
                            <FontAwesomeIcon icon={faChevronLeft}/>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li" className="mx-auto pr-5">
                        <h2 className="mx-auto mb-0"><span className="moduleNumber">4</span> Sein</h2>
                    </Nav.Item>
                </Nav>

                <Container className="col-9">
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum. </p>

                    <Row className="col-10 my-5">
                        <Col className="col-3">
                            <FontAwesomeIcon className="float-right" size="4x" icon={['far', 'file-pdf']} />
                        </Col>
                        <Col className="col-9">
                            <h3>modul_1_arbeitsblatt.pdf</h3>
                            <Button size="sm">
                                <a className="text-white" href="https://firebasestorage.googleapis.com/v0/b/academy-lisastraub.appspot.com/o/My%20Lighthouse%20(A-Dur).pdf?alt=media&token=7f3a934b-d9d8-41cc-9dfa-7ff58a77af66" target="_blank" rel="noopener noreferrer" download>Download</a>
                            </Button>

                        </Col>
                    </Row>


                    <h6>Du hast alles abgeschlossen?</h6>
                    {this.state.m1Done ? (
                        <div>
                            <h2 className="d-inline-flex" onClick={this.finishModule}>
                                <FontAwesomeIcon className="color-success" icon={ faCheckSquare } />
                                <p className="h4 pl-4">Abgeschlossen!</p>
                            </h2>
                            <Button className="d-flex rounded-pill">
                                <Link to={ROUTES.COURSE_1_MODULE_5} className="text-white">Zum nächsten Modul</Link>
                            </Button>
                        </div>
                    ) : (
                        <h2 className="d-inline-flex" onClick={this.finishModule}>
                            <FontAwesomeIcon icon={['far', 'square']} />
                            <p className="h4 pl-4">Modul abschliessen</p>
                        </h2>
                    )
                    }

                </Container>
            </div>
        );
    }
}

const condition = authUser => !!authUser;

export default compose(
    withFirebase,
    withEmailVerification,
    withAuthentication,
    withAuthorization(condition),
    withCourseVerificationOne,
)(Course_1_Module_4);