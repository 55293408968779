import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import { withFirebase } from '../../Firebase';
import * as ROUTES from '../../../constants/routes';
import * as ROLES from '../../../constants/roles';
import SignInGoogle from '../SignInGoogle';
import SignInFacebook from '../SignInFacebook';

// Bootstrap & MUI
import Nav from "react-bootstrap/Nav";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import TextField from "@material-ui/core/TextField";
import CircularProgress from '@material-ui/core/CircularProgress';
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText";

const SignUpPage = () => (
        <SignUpForm />
);

const INITIAL_STATE = {
    firstName: '',
    lastName: '',
    email: '',
    emailVerified: false,
    passwordOne: '',
    passwordTwo: '',
    isAdmin: false,
    createdAt: '',
    newsletter: true,
    error: null,
    loading: false
};

class SignUpFormBase extends Component {
    constructor(props) {
        super(props);

        this.state = { ...INITIAL_STATE };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    onSubmit = event => {
        this.setState({
            loading: true
        });

        const createdAt = new Date().toISOString();
        const { firstName, lastName, email, newsletter, emailVerified, passwordOne, isAdmin } = this.state;

        const roles = {};
        if (isAdmin) {
            roles[ROLES.ADMIN] = ROLES.ADMIN;
        }

        this.props.firebase
            .doCreateUserWithEmailAndPassword(email, passwordOne)
            .then(authUser => {
                return this.props.firebase
                    .user(authUser.user.uid)
                    .set({
                        firstName,
                        lastName,
                        createdAt,
                        email,
                        emailVerified,
                        newsletter,
                        roles,
                    },
                    { merge: true },
                    );
            })
            .then(() => {
                return this.props.firebase.doSendEmailVerification();
            })
            .then(() => {
                this.setState({ ...INITIAL_STATE });
                this.props.history.push(ROUTES.HOME);
            })
            .catch(error => {
                this.setState({ error });
                this.setState({loading: false});
            });
        event.preventDefault();
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    onChangeCheckbox = event => {
        this.setState({ [event.target.name]: event.target.checked });
    };

    render() {
        const {
            firstName,
            lastName,
            email,
            newsletter,
            passwordOne,
            passwordTwo,
            error,
        } = this.state;

        const isInvalid =
            passwordOne !== passwordTwo ||
            passwordOne === '' ||
            email === '' ||
            firstName === '' ||
            lastName === '';

        return (
            <div>
                <Nav defaultActiveKey="/home" as="ul" className="pageTitleDiv">
                    <Nav.Item as="li" className="mx-auto">
                        <h2 className="mb-0">Registrieren</h2>
                    </Nav.Item>
                </Nav>

                <Container className="col-lg-4 bigNavContentWrapper">
                    <form noValidate onSubmit={this.onSubmit}>

                        <TextField id="firstName" name="firstName" type="text" fullWidth
                                   label="Vorname" className="textField my-2"
                                   value={this.state.firstName} onChange={this.onChange}/>

                        <TextField id="lastName" name="lastName" type="text" fullWidth
                                   label="Nachname" className="textField my-2"
                                   value={this.state.lastName} onChange={this.onChange}/>

                        <TextField id="email" name="email" type="email" fullWidth
                                   label="Email" className="textField my-2"
                                   value={this.state.email} onChange={this.onChange}/>

                        <TextField id="passwordOne" name="passwordOne" type="password" fullWidth
                                   label="Passwort" className="textField my-2"
                                   value={this.state.passwordOne} onChange={this.onChange}/>

                        <TextField id="passwordTwo" name="passwordTwo" type="password" fullWidth
                                   label="Bestätige Passwort" className="textField my-2"
                                   value={this.state.passwordTwo} onChange={this.onChange} />

                        {/*{errors.general && (<Typography variant="body2" className={classes.customError}>
                            {errors.general}
                        </Typography> )*/}

                        <FormControlLabel className="mt-3"
                            control={<Checkbox checked={newsletter} onChange={this.onChangeCheckbox} name="newsletter" />}
                            label="Ich möchte Infos über neue Kurse und Angebote erhalten."
                        />
                        <FormHelperText>Deine Daten werden vertraulich behandelt. Durch das Registrieren stimmst du den Nutzungsbedingungen zu.</FormHelperText>

                        <div className="text-center">
                            {error && <p>{error.message}</p>}
                            <br />

                            {isInvalid ? (
                                <Button
                                    variant="primary" className="mt-4 mb-3 rounded-pill"
                                    type="submit" disabled >
                                    Registrieren
                                </Button> )
                            :
                            ( <Button
                                variant="primary" className="mt-4 mb-3 rounded-pill"
                                type="submit" disabled={this.state.loading} >
                                {this.state.loading ? (
                                            <CircularProgress  color="inhert" className="mx-3 mt-2" size={20}>
                                            </CircularProgress>
                                ) : 'Registrieren'}
                            </Button> )}

                            <br />

                            <small>Du hast bereits einen Account? <br />
                            <Link to="/signin">Hier </Link>kannst du dich anmelden.</small>
                        </div>
                    </form>

                    <div className="flex-column d-flex mt-5 align-items-center">
                        <SignInGoogle/>
                        <br/>
                        <SignInFacebook/>
                    </div>

                </Container>
            </div>
        );
    }
}


const SignUpForm = compose(
    withRouter,
    withFirebase,
)(SignUpFormBase);

export default SignUpPage;
export { SignUpForm };
