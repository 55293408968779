import React from 'react';
import './footer.css';

// Bootstrap & Fontawesome
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { faInstagram } from '@fortawesome/free-brands-svg-icons'
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { faHome } from "@fortawesome/free-solid-svg-icons/faHome";
import { faPaperPlane } from '@fortawesome/free-regular-svg-icons';
import {AuthUserContext} from "../Session";

library.add(faFacebookF, faInstagram, faHeart, faPaperPlane, faHome);

const Footer = () => (
    <div>
        <AuthUserContext.Consumer>
            {authUser =>
                authUser ? <FooterAuth authUser={authUser}/> : <FooterNonAuth />
            }
        </AuthUserContext.Consumer>
    </div>
);

class FooterAuth extends React.Component {
    render() {
        return (
            <footer className="text-center font-small" >

                <div className="footer-newsletter row newsletter py-3">
                    <div className="text-center col-md-4 offset-md-4 mt-2 col-8 offset-2">
                        <ul className="list-inline text-center list-unstyled pt-3">
                            <li className="list-inline-item">
                                <a className="p-2 fa-lg tw-ic link" href="https://lisastraub.com" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon className="fontAwesomeIcon" icon={faHome} />
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a className="p-2 fa-lg tw-ic link" href="mailto:lisastraub@bluewin.ch" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon className="fontAwesomeIcon" icon={faPaperPlane} />
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a className="p-2 fa-lg tw-ic link" href="https://www.facebook.com/Sound-and-Healing-1631114200479875/" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon className="fontAwesomeIcon" icon={['fab', 'facebook-f']} />
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a className="p-2 fa-lg tw-ic link" href="https://www.instagram.com/Sound_and_Healing/?fbclid=IwAR0V57Y2Th2zlbWH0Ro_aHnR1nGHgBIs8Ne4mdp6RbNvTsGXspQQbZy5ulc" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon className="fontAwesomeIcon" icon={['fab', 'instagram']} />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="text-white pb-1 footer-copyright-div">
                    <p className="footer-copyright footer-left">Web App with <FontAwesomeIcon className="fontAwesomeIcon" icon={['fas', 'heart']} /> by <a href="https://www.facebook.com/flo.bachofner" target="_blank" rel="noopener noreferrer">Florian Bachofner</a></p>
                    <p className="footer-copyright"><a href="https://lisastraub.com/impressum.html">Impressum</a> | <a href="https://lisastraub.com/datenschutz.html">Datenschutz</a></p>
                    <p className="footer-copyright footer-right">&copy; 2020 by <a href="https://lisastraub.com/impressum.html" >Lisa Straub.</a> Alle Rechte vorbehalten.</p>

                </div>

            </footer>
        )
    }

}

class FooterNonAuth extends React.Component {
    render() {
        return (
            <footer className="text-center font-small footerNonAuth" >

                <div className="footer-newsletter row newsletter py-3">
                    <div className="text-center col-md-4 offset-md-4 mt-2 col-8 offset-2">
                        <h2 id="newsletterTitle">Newsletter</h2>
                        <p>Trage dich ein, um Inspirationen und aktuelle News zu erhalten:</p>
                        <form className="form-inline bg-white py-3 px-2 rounded shadow mb-3"
                              action="https://lisastraub.us17.list-manage.com/subscribe/post?u=579a62061b494c98e713dc37a&amp;id=7798d2ef41" method="post" target="_blank" rel="noopener" noValidate >
                            <input className="form-control  bg-white border-0" id="newsletter-input" type="Email" placeholder="Deine Email..." aria-label="Email" />
                            { /*<div  style="position: absolute; left: -5000px;" aria-hidden="true" >
                                        <input type="text" name="b_579a62061b494c98e713dc37a_7798d2ef41" tabindex="-1" value="" />
                                    </div>*/ }
                            <Button className="btn" id="newsletterButton" type="submit">Anmelden</Button>
                        </form>
                        <ul className="list-inline text-center list-unstyled pt-3">
                            <li className="list-inline-item">
                                <a className="p-2 fa-lg tw-ic link" href="https://lisastraub.com" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon className="fontAwesomeIcon" icon={faHome} />
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a className="p-2 fa-lg tw-ic link" href="mailto:lisastraub@bluewin.ch" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon className="fontAwesomeIcon" icon={faPaperPlane} />
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a className="p-2 fa-lg tw-ic link" href="https://www.facebook.com/Sound-and-Healing-1631114200479875/" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon className="fontAwesomeIcon" icon={['fab', 'facebook-f']} />
                                </a>
                            </li>
                            <li className="list-inline-item">
                                <a className="p-2 fa-lg tw-ic link" href="https://www.instagram.com/Sound_and_Healing/?fbclid=IwAR0V57Y2Th2zlbWH0Ro_aHnR1nGHgBIs8Ne4mdp6RbNvTsGXspQQbZy5ulc" target="_blank" rel="noopener noreferrer">
                                    <FontAwesomeIcon className="fontAwesomeIcon" icon={['fab', 'instagram']} />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className="text-white pb-1 footer-copyright-div">
                    <p className="footer-copyright footer-left">Web App with <FontAwesomeIcon className="fontAwesomeIcon" icon={['fas', 'heart']} /> by <a href="https://www.facebook.com/flo.bachofner" target="_blank" rel="noopener noreferrer">Florian Bachofner</a></p>
                    <p className="footer-copyright"><a href="https://lisastraub.com/impressum.html">Impressum</a> | <a href="https://lisastraub.com/datenschutz.html">Datenschutz</a></p>
                    <p className="footer-copyright footer-right">&copy; 2022 by <a href="https://lisastraub.com/impressum.html" >Lisa Straub.</a> Alle Rechte vorbehalten.</p>

                </div>

            </footer>
        )
    }
}

export default Footer;