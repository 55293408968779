import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';


const withCourseVerificationOne = Component => {
    class withCourseVerificationOne extends React.Component {
        constructor(props) {
            super(props);
            this.state = {
                authUser: JSON.parse(localStorage.getItem('authUser')),
            };
        }

        componentDidMount() {
            this.listener = this.props.firebase.onAuthUserListener(
                authUser => {
                    if (!authUser.course_1) {
                        this.props.history.push(ROUTES.COURSE_1_OVERVIEW);
                    }
                },
                () => this.props.history.push(ROUTES.COURSE_1_OVERVIEW),
            );
        }

        componentWillUnmount() {
            this.listener();
        }

        render() {
            return (
                <AuthUserContext.Provider value={this.state.authUser}>
                    <Component {...this.props} />
                </AuthUserContext.Provider>
            );
        }
    }

    return compose(
        withRouter,
        withFirebase,
    )(withCourseVerificationOne);
};

export default withCourseVerificationOne;