import React, { Component } from 'react';
import {Link, withRouter} from 'react-router-dom';
import { compose } from 'recompose';

import { PasswordForgetLink } from '../../PasswordForget';
import { withFirebase } from '../../Firebase';
import * as ROUTES from '../../../constants/routes';
import SignInGoogle from '../SignInGoogle';

// Bootstrap & MUI
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import TextField from "@material-ui/core/TextField";
import CircularProgress from '@material-ui/core/CircularProgress';
import Nav from "react-bootstrap/Nav";


const SignInPage = () => (
    <SignInForm />
);
const INITIAL_STATE = {
    email: '',
    password: '',
    error: null,
    loading: false,
};

class SignInFormBase extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    onSubmit = event => {
        this.setState({ loading: true });
        const { email, password } = this.state;

        this.props.firebase
            .doSignInWithEmailAndPassword(email, password)
            .then(() => {
                this.setState({ ...INITIAL_STATE });
                this.props.history.push(ROUTES.HOME);
            })
            .catch(error => {
                this.setState({ loading: false });
                this.setState({ error });
            });
        event.preventDefault();
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { email, password, error } = this.state;
        const isInvalid = password === '' || email === '';

        return (
            <div>
                <Nav defaultActiveKey="/home" as="ul" className="pageTitleDiv">
                    <Nav.Item as="li" className="mx-auto">
                        <h2 className="mb-0">Anmelden</h2>
                    </Nav.Item>
                </Nav>

                <Container className="col-lg-4 bigNavContentWrapper">
                    <form noValidate onSubmit={this.onSubmit}>

                        <TextField id="email" name="email" type="email" fullWidth
                                   label="Email" className="textField my-2"
                                   value={this.state.email} onChange={this.onChange}/>

                        <TextField id="password" name="password" type="password" fullWidth
                                   label="Passwort" className="textField my-2"
                                   value={this.state.password} onChange={this.onChange}/>


                        {/*{errors.general && (<Typography variant="body2" className={classes.customError}>
                            {errors.general}
                        </Typography> )*/}

                        <div className="text-center">
                            {error && <p>{error.message}</p>}
                            <br />


                            {isInvalid ? (
                                    <Button
                                        variant="primary" className="my-3 rounded-pill"
                                        type="submit" disabled >
                                        Anmelden
                                    </Button> )
                                :
                                ( <Button
                                    variant="primary" className="my-3 rounded-pill"
                                    type="submit" disabled={this.state.loading} >
                                    {this.state.loading ? (
                                                <CircularProgress  color="inhert" className="mx-3 mt-2" size={20}>
                                                </CircularProgress>
                                    ) : 'Anmelden'}
                                </Button> )}


                            <br />

                            <PasswordForgetLink />
                            <br />

                            <small>Du hast noch keinen Account? <br />
                                <Link to="/signup">Hier </Link>kannst du dich registriern.</small>
                        </div>
                    </form>

                    <div className="flex-column d-flex mt-5 align-items-center">
                        <SignInGoogle/>
                        <br/>
                        <SignInFacebook/>
                    </div>

                </Container>
            </div>
        );
    }
}

class SignInFacebookBase extends Component {
    constructor(props) {
        super(props);
        this.state = { error: null };
    }
    onSubmit = event => {
        this.props.firebase
            .doSignInWithFacebook()
            .then(socialAuthUser => {
                // Create a user in your Firebase Realtime Database too
                return this.props.firebase
                    .user(socialAuthUser.user.uid)
                    .set({
                        username: socialAuthUser.additionalUserInfo.profile.name,
                        email: socialAuthUser.additionalUserInfo.profile.email,
                        roles: {},
                    });
            })
            .then(socialAuthUser => {
                this.setState({ error: null });
                this.props.history.push(ROUTES.HOME);
            })
            .catch(error => {
                this.setState({ error });
            });
        event.preventDefault();
    };
    render() {
        const { error } = this.state;
        return (
            <form onSubmit={this.onSubmit}>
                <Button type="submit" className="rounded-pill" variant="outline-info" >mit Facebook</Button>
                {error && <p>{error.message}</p>}
            </form>
        );
    }
}

const SignInForm = compose(
    withRouter,
    withFirebase,
)(SignInFormBase);

const SignInFacebook = compose(
    withRouter,
    withFirebase,
)(SignInFacebookBase);

export default SignInPage;
export { SignInForm, SignInFacebook };