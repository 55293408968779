import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import Container from "react-bootstrap/Container";
import TextField from "@material-ui/core/TextField";
import Button from "react-bootstrap/Button";

const INITIAL_STATE = {
    email: '',
    error: null,
    isSent: false,
};

class PasswordForgetPage extends Component {
    onSendEmailVerification = () => {
        this.props.firebase.doSendEmailVerification()
            .then(() => this.setState({ isSent: true }));
    };
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };
    }

    onSubmit = event => {
        const { email } = this.state;
        this.props.firebase
            .doPasswordReset(email)
            .then(() => {
                this.setState({ isSent: true });
            })
            .catch(error => {
                this.setState({ error });
            });
        event.preventDefault();
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { email, error } = this.state;
        const isInvalid = email === '';
        return (
            <Container className="bigNavContentWrapper">
                <h2>Kennwort vergessen?</h2>
                <hr />

                { !this.state.isSent ? (
                    <div>
                        <p className="ml-3 pt-3">Geben Sie Ihre E-Mail-Adresse ein, um Ihr Kennwort zurückzusetzen.<br/>
                    Eventuell müssen Sie den Spamordner prüfen.</p>

                    <form onSubmit={this.onSubmit} className="mt-4 col-7">
                    <TextField id="email" name="email" type="text" fullWidth
                    label="Email Adresse" className="textField my-2"
                    value={this.state.email} onChange={this.onChange}/>

                    <Button type="submit" color="primary" className="my-3 rounded-pill" disabled={isInvalid}>
                    Senden
                    </Button>
                    {error && <p>{error.message}</p>}
                    </form>
                    </div>
                ) : (
                    <div className="pb-5 mb-5">
                        <p className="pb-5 mb-5">Ihre Mail wurde versendet. Eventuell ist sie auch im Spam gelandet.</p>
                    </div>
                )}

            </Container>
        );
    }
}

const PasswordForgetLink = () => (
    <p>
        <Link to={ROUTES.PASSWORD_FORGET}>Passwort vergessen?</Link>
    </p>
);

export default withFirebase(PasswordForgetPage);


export { PasswordForgetLink };
