import React, { Component } from 'react';
import {compose} from "recompose";
import {withFirebase} from "../../Firebase";
import {withAuthorization, withEmailVerification, withCourseVerificationOne} from "../../Session";

import * as ROUTES from "../../../constants/routes";

// Bootstrap
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Image from "react-bootstrap/Image";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";

import image_course_1 from './image_course_1.jpg';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";


class Course_1_Content extends Component {
    constructor(props) {
        super(props);

        this.state = {
            authUser: JSON.parse(localStorage.getItem('authUser')),
        };
    }


    render() {
        const { authUser } = this.state;
        const m1Done = (Object.values(authUser.course_1["m1"]).toString().split(",").join("") === "done");
        const m2Done = (Object.values(authUser.course_1["m2"]).toString().split(",").join("") === "done");
        const m3Done = (Object.values(authUser.course_1["m3"]).toString().split(",").join("") === "done");
        const m4Done = (Object.values(authUser.course_1["m4"]).toString().split(",").join("") === "done");
        const m5Done = (Object.values(authUser.course_1["m5"]).toString().split(",").join("") === "done");
        const m6Done = (Object.values(authUser.course_1["m6"]).toString().split(",").join("") === "done");
        const sDone = (Object.values(authUser.course_1["s1"]).toString().split(",").join("") === "done");

        return (
            <div>
                <Nav defaultActiveKey="/home" as="ul" className="pageTitleDiv">
                    <Nav.Item as="li">
                        <Nav.Link href="/home" className="text-white">
                            <FontAwesomeIcon icon={faChevronLeft}/>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li" className="mx-auto pr-5">
                        <h2 className="mx-auto mb-0">Trennung - Neubeginn</h2>
                    </Nav.Item>
                </Nav>
                <div className="pageSubTitleDiv d-flex">
                    <p className="pageSubTitle mx-auto">Module</p>
                </div>

                <Container className="mt-5 col-7">

                    <a href={ROUTES.COURSE_1_MODULE_1} className="module-link">
                        <Row className="shadow module-card" border="light">
                            <Col className="my-3" xs={4}>
                                <Image src={image_course_1} fluid/>
                            </Col>
                            <Col className="my-4 d-block">
                                <h6 className="text-muted">Modul 1</h6>
                                <h2>Fühlen</h2>
                                <p>Kurzer Text zum Modul.</p>
                                { m1Done ? (
                                    <Button size="sm" className="rounded-pill px-2" variant="success">
                                        <FontAwesomeIcon className="text-white" icon={ faCheckCircle } />
                                        &nbsp; Abgeschlossen
                                    </Button>
                                ):(
                                    <Button size="sm" className="rounded-pill px-2">Weiterlesen</Button>
                                )}
                            </Col>
                            { m1Done && (
                                <div className="module-done-bar">
                                </div>
                            )}
                        </Row>
                    </a>

                    <a href={ROUTES.COURSE_1_MODULE_2} className="module-link">
                        <Row className="shadow module-card" border="light">
                            <Col className="my-3" xs={4}>
                                <Image src={image_course_1} fluid/>
                            </Col>
                            <Col className="my-4 d-block">
                                <h6 className="text-muted">Modul 2</h6>
                                <h2>Verstehen</h2>
                                <p>Kurzer Text zum Modul.</p>
                                { m2Done ? (
                                    <Button size="sm" className="rounded-pill px-2" variant="success">
                                        <FontAwesomeIcon className="text-white" icon={ faCheckCircle } />
                                        &nbsp; Abgeschlossen
                                    </Button>
                                ):(
                                    <Button size="sm" className="rounded-pill px-2">Weiterlesen</Button>
                                )}
                            </Col>
                            { m2Done && (
                                <div className="module-done-bar">
                                </div>
                            )}
                        </Row>
                    </a>

                    <a href={ROUTES.COURSE_1_MODULE_3} className="module-link">
                        <Row className="shadow module-card" border="light">
                            <Col className="my-3" xs={4}>
                                <Image src={image_course_1} fluid/>
                            </Col>
                            <Col className="my-4 d-block">
                                <h6 className="text-muted">Modul 3</h6>
                                <h2>Loslassen</h2>
                                <p>Kurzer Text zum Modul.</p>
                                { m3Done ? (
                                    <Button size="sm" className="rounded-pill px-2" variant="success">
                                        <FontAwesomeIcon className="text-white" icon={ faCheckCircle } />
                                        &nbsp; Abgeschlossen
                                    </Button>
                                ):(
                                    <Button size="sm" className="rounded-pill px-2">Weiterlesen</Button>
                                )}
                            </Col>
                            { m3Done && (
                                <div className="module-done-bar">
                                </div>
                            )}
                        </Row>
                    </a>

                </Container>

                <Row className="sessionRow">
                    <Container className="col-7">
                        <a href={ROUTES.COURSE_1_SESSION_1} className="module-link">
                            <Row className="shadow module-card" border="">
                                <Col className="my-3" xs={4}>
                                    <Image src={image_course_1} fluid/>
                                </Col>
                                <Col className="my-4 d-block">
                                    <h6 className="text-muted">1 : 1</h6>
                                    <h2>Einzelsitzung</h2>
                                    <p>Kurzer Text zur Session.</p>
                                    { sDone ? (
                                        <Button size="sm" className="rounded-pill px-2" variant="success">
                                            <FontAwesomeIcon className="text-white" icon={ faCheckCircle } />
                                            &nbsp; Erledigt
                                        </Button>
                                    ):(
                                        <Button size="sm" className="rounded px-4">Jetzt buchen</Button>
                                    )}
                                </Col>
                                { sDone && (
                                    <div className="module-done-bar">
                                    </div>
                                )}
                            </Row>
                        </a>
                    </Container>
                </Row>

                <Container className="col-7">

                    <a href={ROUTES.COURSE_1_MODULE_4} className="module-link">
                        <Row className="shadow module-card" border="light">
                            <Col className="my-3" xs={4}>
                                <Image src={image_course_1} fluid/>
                            </Col>
                            <Col className="my-4 d-block">
                                <h6 className="text-muted">Modul 4</h6>
                                <h2>Sein</h2>
                                <p>Kurzer Text zum Modul.</p>
                                { m4Done ? (
                                    <Button size="sm" className="rounded-pill px-2" variant="success">
                                        <FontAwesomeIcon className="text-white" icon={ faCheckCircle } />
                                        &nbsp; Abgeschlossen
                                    </Button>
                                ):(
                                    <Button size="sm" className="rounded-pill px-2">Weiterlesen</Button>
                                )}
                            </Col>
                            { m4Done && (
                                <div className="module-done-bar">
                                </div>
                            )}
                        </Row>
                    </a>

                    <a href={ROUTES.COURSE_1_MODULE_5} className="module-link">
                        <Row className="shadow module-card" border="light">
                            <Col className="my-3" xs={4}>
                                <Image src={image_course_1} fluid/>
                            </Col>
                            <Col className="my-4 d-block">
                                <h6 className="text-muted">Modul 5</h6>
                                <h2>Kreieren</h2>
                                <p>Kurzer Text zum Modul.</p>
                                { m5Done ? (
                                    <Button size="sm" className="rounded-pill px-2" variant="success">
                                        <FontAwesomeIcon className="text-white" icon={ faCheckCircle } />
                                        &nbsp; Abgeschlossen
                                    </Button>
                                ):(
                                    <Button size="sm" className="rounded-pill px-2">Weiterlesen</Button>
                                )}
                            </Col>
                            { m5Done && (
                                <div className="module-done-bar">
                                </div>
                            )}
                        </Row>
                    </a>

                    <a href={ROUTES.COURSE_1_MODULE_6} className="module-link">
                        <Row className="shadow module-card" border="light">
                            <Col className="my-3" xs={4}>
                                <Image src={image_course_1} fluid/>
                            </Col>
                            <Col className="my-4 d-block">
                                <h6 className="text-muted">Modul 6</h6>
                                <h2>Empfangen</h2>
                                <p>Kurzer Text zum Modul.</p>
                                { m6Done ? (
                                    <Button size="sm" className="rounded-pill px-2" variant="success">
                                        <FontAwesomeIcon className="text-white" icon={ faCheckCircle } />
                                        &nbsp; Abgeschlossen
                                    </Button>
                                ):(
                                    <Button size="sm" className="rounded-pill px-2">Weiterlesen</Button>
                                )}
                            </Col>
                            { m6Done && (
                                <div className="module-done-bar">
                                </div>
                            )}
                        </Row>
                    </a>
                </Container>

            </div>
        );
    }
}

const condition = authUser => !!authUser;


export default compose(
    withFirebase,
    withEmailVerification,
    withAuthorization(condition),
    withCourseVerificationOne,
)(Course_1_Content);