import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { compose } from 'recompose';

import UserItemBase from "./UserItem";

import UserListBase from "./UserList";

import { withAuthorization, withEmailVerification } from '../Session';
import * as ROLES from '../../constants/roles';
import * as ROUTES from '../../constants/routes';

// Bootstrap
import Nav from "react-bootstrap/Nav";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";


const AdminPage = () => (
    <div>
        <Nav defaultActiveKey="/home" as="ul" className="pageTitleDiv">
            <Nav.Item as="li">
                <Nav.Link href="/home" className="text-white">
                    <FontAwesomeIcon icon={faChevronLeft}/>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li" className="mx-auto pr-5">
                <h2 className="mx-auto mb-0">Admin</h2>
            </Nav.Item>
        </Nav>

        <Switch>
            <Route exact path={ROUTES.ADMIN_DETAILS} component={UserItemBase} />
            <Route exact path={ROUTES.ADMIN} component={UserListBase} />
        </Switch>
    </div>
);

const condition = authUser =>
    authUser && !!authUser.roles[ROLES.ADMIN];


export default compose(
    withEmailVerification,
    withAuthorization(condition),
)(AdminPage);

