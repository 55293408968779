import React, { Component } from 'react';
import {compose} from "recompose";
import {withFirebase} from "../../Firebase";
import {withAuthorization, withEmailVerification, withCourseVerificationTwo} from "../../Session";

/*
import video1 from "./videos/1.mp4";
import video2 from "./videos/2.mp4";
import video3 from "./videos/3.mp4";
import video4 from "./videos/4.mp4";
import video5 from "./videos/5.mp4";
import video6 from "./videos/6.mp4";
*/

import * as ROUTES from "../../../constants/routes";

// Bootstrap
import Nav from "react-bootstrap/Nav";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import Container from "react-bootstrap/Container";

class Course_2_Content extends Component {

    render() {
        return (
            <div>

                <Nav defaultActiveKey={ROUTES.HOME} as="ul" className="pageTitleDiv">
                    <Nav.Item as="li">
                        <Nav.Link href={ROUTES.HOME} className="text-white">
                            <FontAwesomeIcon icon={faChevronLeft}/>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li" className="mx-auto pr-5">
                        <h3 className="mx-auto mb-0">Fülle pur</h3>
                    </Nav.Item>
                </Nav>

                <Container className="text-center">
                    <h2 className="mb-5">Herzlich Willkommen!</h2>
                    <p>Auf dieser Seite werden im Laufe des Kurses Informationen sowie die Video-Aufzeichnungen hochgeladen.</p>
                    <cite className="m-5">Schön bist du mit dabei!</cite> <br/><br/><br/>

                    {/*

                    <h4>Aufzeichnung 1 &nbsp; - &nbsp; 2. Okt 2021</h4>
                    <p>Im Einstieg zum Kurs geht es darum, deine verschiedenen Lebensbereiche anerkennend wahrzunehmen.<br />
                        Wir lernen die ersten Werkzeuge um deinem Leben mehr Magie zu verleihen.</p>

                    <video width="750" height="500" controls >
                        <source src={video1} type="video/mp4"/>
                    </video>

                    <h4>Aufzeichnung 2 &nbsp; - &nbsp; 6. Okt 2021</h4>
                    <p>Deine Körpergeschichte und der "Danke Körper" Prozess.</p>

                    <video width="750" height="500" controls >
                        <source src={video2} type="video/mp4"/>
                    </video>

                    <h4>Aufzeichnung 3 &nbsp; - &nbsp; 9. Okt 2021</h4>
                    <p>Den Körper auf Empfang stellen</p>

                    <video width="750" height="500" controls >
                        <source src={video3} type="video/mp4"/>
                    </video>

                    <h4>Aufzeichnung 4 &nbsp; - &nbsp; 13. Okt 2021</h4>
                    <p>Körperprozesse: Auflösung und Freisetzung von eingeschlossenen Aggressionen und Emotionen -
                        Nullsumme von Traumen anzetteln - Auflösung und Freisetzung von kontraproduktiven Schaltkreisen,
                        Automatismen und biomimetrischen Nachahmungen.</p>

                    <video width="750" height="500" controls >
                        <source src={video4} type="video/mp4"/>
                    </video>

                    <h4>Aufzeichnung 5 &nbsp; - &nbsp; 16. Okt 2021</h4>
                    <p>Mangel beginnt im Kopf - Fülle im Herzen<br />
                        Körperprozess: Gegenteil
                    </p>

                    <video width="750" height="500" controls >
                        <source src={video5} type="video/mp4"/>
                    </video>

                    <h4>Aufzeichnung 6 &nbsp; - &nbsp; 20. Okt 2021</h4>
                    <p>Abschlusszoom mit Ritual zum Vollmond</p>

                    <video width="750" height="500" controls >
                        <source src={video6} type="video/mp4"/>
                    </video>

                    */}


                </Container>

            </div>
        );
    }
}

const condition = authUser => !!authUser;


export default compose(
    withFirebase,
    withEmailVerification,
    withAuthorization(condition),
    withCourseVerificationTwo,
)(Course_2_Content);