import React, { Component } from 'react';

import {compose} from "recompose";
import {withFirebase} from "../../Firebase";
import {withAuthorization, withEmailVerification} from "../../Session";
import * as ROUTES from "../../../constants/routes";

// Bootstrap & FontAwesome
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import PayPalButtonCourse1 from "../../PayPalButton/PayPalButtonCourse1";

class Course_1_Overview extends Component {
    constructor(props) {
        super(props);

        this.state = {
            authUser: JSON.parse(localStorage.getItem('authUser'))
        };
    }

    onClick = event => {
        this.props.firebase
            .addCourseOne()
            .then(() => {
                this.props.history.push(ROUTES.COURSE_1_CONTENT);
            })
            .catch(error => {
                console.error(error);
            });
    };

    render() {
        return (
            <div>
                <Nav defaultActiveKey="/home" as="ul" className="pageTitleDiv">
                    <Nav.Item as="li">
                        <Nav.Link href="/home" className="text-white">
                            <FontAwesomeIcon icon={faChevronLeft}/>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li" className="mx-auto pr-5 d-flex">
                        <h2 className="mx-auto mb-0">Trennung - Neubeginn</h2>
                    </Nav.Item>
                </Nav>
                <div className="pageSubTitleDiv d-flex">
                    <p className="pageSubTitle mx-auto">Inhalt</p>
                </div>

                <Container className="text-center mt-4">
                    <p>Info zum Kurs.</p>
                    <p>Preis</p>
                    <cite className="m-5">Wichtiger Kurs</cite> <br/><br/><br/>
                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.</p>
                    <h3>Weitere Infos</h3>
                    <p>Uhrzeit usw.</p>
                    <hr/>
                    <br/><br/>
                    <p>Lisa Straub</p>
                    <p>There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend to repeat predefined chunks as necessary, making this the first true generator on the Internet. It uses a dictionary of over 200 Latin words, combined with a handful of model sentence structures, to generate Lorem Ipsum which looks reasonable. The generated Lorem Ipsum is therefore always free from repetition, injected humour, or non-characteristic words etc.</p>
                    <h3>Weitere Infos</h3>
                    <p>Uhrzeit usw.</p>
                    <hr/>
                    <br/><br/>

                    { this.state.authUser.course_1 ?
                        <div>
                            <Button variant="primary" className="d-inline-flex" href={ROUTES.COURSE_1_CONTENT}>Jetzt loslegen</Button>
                        </div>
                        :
                        <div>
                            <p className="mt-4 d-flex justify-content-center"> Jetzt kaufen</p>
                            <div className="mt-1 d-flex justify-content-center">
                                <PayPalButtonCourse1 />
                            </div>
                        </div>
                    }

                </Container>

            </div>
        );
    }
}

const condition = authUser => !!authUser;


export default compose(
    withFirebase,
    withEmailVerification,
    withAuthorization(condition),
)(Course_1_Overview);