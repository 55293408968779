import React, { Component } from 'react';
import { withFirebase } from '../Firebase';
import TextField from "@material-ui/core/TextField";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";

const INITIAL_STATE = {
    passwordOne: '',
    passwordTwo: '',
    passwordReset: false,
    error: null,
};

class PasswordChangeForm extends Component {
    constructor(props) {
        super(props);
        this.state = { ...INITIAL_STATE };

        this.onChange = this.onChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    onSubmit = event => {
        const { passwordOne } = this.state;
        this.props.firebase
            .doPasswordUpdate(passwordOne)
            .then(() => {
                this.setState({ ...INITIAL_STATE });
                this.setState({passwordReset: true})
            })
            .catch(error => {
                this.setState({ error });
            });
        event.preventDefault();
    };

    onChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    };

    render() {
        const { passwordOne, passwordTwo, passwordReset, error } = this.state;
        const isInvalid =
            passwordOne !== passwordTwo || passwordOne === '';

        return (
            <Container>
                <h2>Kennwort anpassen</h2>
                <hr />

                { !passwordReset ? (
                    <div>
                        <p className="ml-3 pt-3">Geben Sie hier das neue Kennwort zweimal ein.</p>

                        <form onSubmit={this.onSubmit} className="mt-4 col-7">

                            <TextField id="passwordOne" name="passwordOne" type="password" fullWidth
                                       label="Neues Passwort" className="textField my-2"
                                       value={this.state.passwordOne} onChange={this.onChange}/>

                            <TextField id="passwordTwo" name="passwordTwo" type="password" fullWidth
                                       label="Passwort wiederholen" className="textField my-2"
                                       value={this.state.passwordTwo} onChange={this.onChange}/>

                            <Button type="submit" color="primary" className="my-3 rounded-pill" disabled={isInvalid}>
                                Senden
                            </Button>
                            {error && <p>{error.message}</p>}
                        </form>
                    </div>
                ) : (
                    <p>Ihr Password wurde erfolgreich zurückgesetzt. Sie können sich mit diesem in Zukunft einloggen.</p>
                )}

            </Container>
        );
    }
}

export default withFirebase(PasswordChangeForm);
