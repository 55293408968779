import React from 'react';
import {
    BrowserRouter as Router,
    Route,
} from 'react-router-dom';

import { withAuthentication } from '../Session';

import Navigation from '../Navigation/Navigation';
import LandingPage from '../Landing/Landing';
import SignUpPage from '../AuthProcess/SignUp/SignUp';
import SignInPage from '../AuthProcess/SignIn/SignIn';
import PasswordForgetPage from '../PasswordForget';
import Dashboard from '../Dashboard/Dashboard';
import AccountPage from '../Account/Account';
import AdminPage from '../Admin/Admin';
import SignUpWelcome from "../AuthProcess/SignUp/SignUpWelcome";
import Footer from '../Footer/Footer';

import Course_1_Overview from "../Courses/Course_1/course_1_overview";
import Course_1_Content from "../Courses/Course_1/course_1_content";
import Course_1_Module_1 from "../Courses/Course_1/Module_1/Module_1";
import Course_1_Module_2 from "../Courses/Course_1/Module_2/Module_2";
import Course_1_Module_3 from "../Courses/Course_1/Module_3/Module_3";
import Course_1_Module_4 from "../Courses/Course_1/Module_4/Module_4";
import Course_1_Module_5 from "../Courses/Course_1/Module_5/Module_5";
import Course_1_Module_6 from "../Courses/Course_1/Module_6/Module_6";
import Course_1_Session_1 from "../Courses/Course_1/Session_1/Session_1";
import Course_1_ThankYou from "../Courses/Course_1/course_1_thankyou";

import Course_2_Overview from "../Courses/Course_2/course_2_overview";
import Course_2_Content from "../Courses/Course_2/course_2_content";

import Course_3_Overview from "../Courses/Course_3/course_3_overview";
import Course_3_Content from "../Courses/Course_3/course_3_content"

import * as ROUTES from '../../constants/routes';

// Boostrap
import './style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';


// dotenv
const dotenv = require('dotenv');
dotenv.config();



const App = () => (
     <Router>
            <div className="PageWrapper">
                <Navigation />
                <div className="ContentWrapper">
                    <Route exact path={ROUTES.LANDING} component={LandingPage} />
                    <Route path={ROUTES.SIGN_UP} component={SignUpPage}/>
                    <Route path={ROUTES.SIGN_IN} component={SignInPage}/>
                    <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
                    <Route path={ROUTES.ACCOUNT} component={AccountPage} />
                    <Route path={ROUTES.HOME} component={Dashboard} />
                    <Route path={ROUTES.ADMIN} component={AdminPage} />
                    <Route path={ROUTES.WELCOME} component={SignUpWelcome} />

                    <Route path={ROUTES.COURSE_1_OVERVIEW} component={Course_1_Overview} />
                    <Route path={ROUTES.COURSE_1_CONTENT} component={Course_1_Content} />
                        <Route path={ROUTES.COURSE_1_THANKYOU} component={Course_1_ThankYou} />
                        <Route path={ROUTES.COURSE_1_MODULE_1} component={Course_1_Module_1} />
                        <Route path={ROUTES.COURSE_1_MODULE_2} component={Course_1_Module_2} />
                        <Route path={ROUTES.COURSE_1_MODULE_3} component={Course_1_Module_3} />
                        <Route path={ROUTES.COURSE_1_SESSION_1} component={Course_1_Session_1} />
                        <Route path={ROUTES.COURSE_1_MODULE_4} component={Course_1_Module_4} />
                        <Route path={ROUTES.COURSE_1_MODULE_5} component={Course_1_Module_5} />
                        <Route path={ROUTES.COURSE_1_MODULE_6} component={Course_1_Module_6} />

                    <Route path={ROUTES.COURSE_2_OVERVIEW} component={Course_2_Overview} />
                    <Route path={ROUTES.COURSE_2_CONTENT} component={Course_2_Content} />

                    <Route path={ROUTES.COURSE_3_OVERVIEW} component={Course_3_Overview} />
                    <Route path={ROUTES.COURSE_3_CONTENT} component={Course_3_Content} />


                </div>

                <Footer />
            </div>

     </Router>
);

export default withAuthentication(App);
