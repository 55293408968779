import React, { Component } from 'react';
import {compose} from "recompose";
import {withFirebase} from "../../Firebase";
import {withAuthorization, withEmailVerification, } from "../../Session";
import * as ROUTES from "../../../constants/routes";


// Bootstrap
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";

import CircularProgress from "@material-ui/core/CircularProgress";
import Nav from "react-bootstrap/Nav";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import withAuthentication from "../../Session/withAuthentication";


class Course_1_ThankYou extends Component {

    constructor(props) {
        super(props);
        this.enableMessage = this.enableMessage.bind(this);

        this.state = {
            displayMessage: false,
        };

        this.timer = setTimeout(this.enableMessage, 1000);
    }

    componentWillUnmount() {
        clearTimeout(this.timer);
    }

    enableMessage() {
        this.setState({displayMessage: true});
    }


    render() {
        const {displayMessage} = this.state;

        return (
            <div>
                <Nav defaultActiveKey="/home" as="ul" className="pageTitleDiv">
                    <Nav.Item as="li">
                        <Nav.Link href="/home" className="text-white">
                            <FontAwesomeIcon icon={faChevronLeft}/>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li" className="mx-auto pr-5">
                        <h2 className="mx-auto mb-0">Danke!</h2>
                    </Nav.Item>
                </Nav>

                <Container className="mt-5 col-7">


                    <p>Dein Kurs wird gekauft, dies dauert ca. 1 Minute...</p>
                    <p>Danach kannst du gleich hier loslegen:</p>

                    {!displayMessage ? (
                        <Button disabled>
                            <CircularProgress  color="inhert" className="mx-3 mt-2" size={20}>
                            </CircularProgress>
                        </Button>

                    ) : (
                        <Button href={ROUTES.COURSE_1_CONTENT}>
                            Zu den Modulen
                        </Button>
                    )}

                    {this.displayMessage && <p>HELLO</p>}

                </Container>

            </div>
        );
    }
}

const condition = authUser => !!authUser;


export default compose(
    withFirebase,
    withAuthentication,
    withEmailVerification,
    withAuthorization(condition)
)(Course_1_ThankYou);