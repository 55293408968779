import React from "react";
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/Button";

import {withFirebase} from "../Firebase";

class AccountDeleteModal extends React.Component {

    deleteUser = () => {
        this.props.firebase.doDeleteUser().then(() => {
            }
        )
    };
    render() {
        return (
            <Modal
                {...this.props}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        Account endgültig löschen?
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Dies kann nicht rückgängig gemacht werden. Wir behalten keine deiner Daten auf, auch nicht zu allfällig gekauften Kursen.
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={this.props.onHide}>Zurück</Button>
                    <Button className="my-4" onClick={this.deleteUser}>
                        Accont löschen
                    </Button>
                </Modal.Footer>
            </Modal>
        )
    }
}

export default withFirebase(AccountDeleteModal);