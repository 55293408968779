import React, { Component } from 'react';
import {compose} from "recompose";
import {withFirebase} from "../../Firebase";
import {withAuthorization, withEmailVerification, withCourseVerificationThree} from "../../Session";


import video1 from "./videos/1.mp4";
import video2 from "./videos/2.mp4";
// import video3 from "./videos/3.mp4";
import video4 from "./videos/4.mp4";
/*
import video5 from "./videos/5.mp4";
import video6 from "./videos/6.mp4";
*/

import * as ROUTES from "../../../constants/routes";

// Bootstrap
import Nav from "react-bootstrap/Nav";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import Container from "react-bootstrap/Container";

class Course_3_Content extends Component {

    render() {
        return (
            <div>

                <Nav defaultActiveKey={ROUTES.HOME} as="ul" className="pageTitleDiv">
                    <Nav.Item as="li">
                        <Nav.Link href={ROUTES.HOME} className="text-white">
                            <FontAwesomeIcon icon={faChevronLeft}/>
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li" className="mx-auto pr-5">
                        <h3 className="mx-auto mb-0">Fülle pur <small>- Januar 2022</small></h3>
                    </Nav.Item>
                </Nav>

                <Container className="text-center">
                    <h2 className="mb-5">Herzlich Willkommen!</h2>
                    <p>Auf dieser Seite werden im Laufe des Kurses Informationen sowie die Video-Aufzeichnungen hochgeladen.</p>
                    <cite className="m-5">Schön bist du mit dabei!</cite> <br/><br/><br/>

                    <h4 className="mt-5">Aufzeichnung 1 &nbsp; - &nbsp; 17. Januar 2022</h4>
                    <p>Erste Schritte auf dem roten Teppich
                    <br/>Referenz- und Resonanzpunkte
                    <br/>Wahl treffen, Fragen stellen, Impulsen folgen
                    <br/>Nutzlos sein</p>

                    <video width="750" height="500" controls >
                        <source src={video1} type="video/mp4"/>
                    </video>

                    <h4 className="mt-5">Aufzeichnung 2 &nbsp; - &nbsp; 31. Januar 2022</h4>
                    <p>Heilung deiner Körpergeschichte
                        <br/>Körperprozess: Danke Körper
                        <br/>Oma - Tool
                        <br/>Dem Körper Fragen stellen</p>

                    <video width="750" height="500" controls >
                        <source src={video2} type="video/mp4"/>
                    </video>

                    <h4>Aufzeichnung 3 &nbsp; - &nbsp; 31. Januar 2022</h4>
                    <p>Kommt demnächst!</p>

                    {/*
                    <video width="750" height="500" controls >
                        <source src={video3} type="video/mp4"/>
                    </video>
                    */}



                    <h4>Aufzeichnung 4 &nbsp; - &nbsp; 7. Februar 2022</h4>
                    <p></p>

                    <video width="750" height="500" controls >
                        <source src={video4} type="video/mp4"/>
                    </video>
                    {/*
                    <h4>Aufzeichnung 5 &nbsp; - &nbsp; 16. Okt 2021</h4>
                    <p>Mangel beginnt im Kopf - Fülle im Herzen<br />
                        Körperprozess: Gegenteil
                    </p>

                    <video width="750" height="500" controls >
                        <source src={video5} type="video/mp4"/>
                    </video>

                    <h4>Aufzeichnung 6 &nbsp; - &nbsp; 20. Okt 2021</h4>
                    <p>Abschlusszoom mit Ritual zum Vollmond</p>

                    <video width="750" height="500" controls >
                        <source src={video6} type="video/mp4"/>
                    </video>*/}


                </Container>

            </div>
        );
    }
}

const condition = authUser => !!authUser;


export default compose(
    withFirebase,
    withEmailVerification,
    withAuthorization(condition),
    withCourseVerificationThree,
)(Course_3_Content);