import React from 'react';
import { withFirebase } from '../../Firebase';

// Bootstrap
import Button from "react-bootstrap/Button";

const SignOutButton = ({ firebase }) => (
    <Button type="button" onClick={firebase.doSignOut} variant="primary">
        Abmelden
    </Button>
);

export default withFirebase(SignOutButton);