import React from 'react';
import { compose } from "recompose";
import { withAuthorization, withEmailVerification } from '../Session';
import { withFirebase } from '../Firebase';
import * as ROUTES from "../../constants/routes";

// Bootstrap
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import './dashboard.css';
import image_course_3 from '../Courses/Course_3/image_course_3.jpeg';
import withAuthentication from "../Session/withAuthentication";

class DashboardCardCourse3 extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            authUser: JSON.parse(localStorage.getItem('authUser')),
            progress: 0,
        };
    }

    componentDidMount() {
        const { uid, course_3 } = this.state.authUser;
        this.getProgress(uid, course_3);
    }

    getProgress(authUser, course_1) {
        if (course_1) {
            this.props.firebase.calculateCourseOneProgress(authUser)
                .then((num) => {
                    this.setState({ progress: num });
                })
                .catch(error => {
                    console.error(error);
                });
        }
    }

    render() {
        return (
            <div className="col-4">
                <Card className="shadow d-inline-flex embed-responsive">
                    <Card.Img variant="top" src={image_course_3} className="embed-responsive" />

                    <Card.Body>
                        <Card.Text className="h2 pb-2">
                            Fülle pur <small>Januar 2022</small>
                        </Card.Text>

                        { this.state.authUser.course_3 ?
                            <Card.Text className="text-muted h5 pb-3">
                                Eingeschrieben
                            </Card.Text>
                            :
                            <Card.Text className="text-muted h5 pb-3">
                                Januar 2022
                            </Card.Text>
                        }

                        <Card.Text>
                            Wir rollen 2022 den roten Teppich aus und manifestieren Fülle auf allen Ebenen.
                            <br/><br/>
                        </Card.Text>

                        { this.state.authUser.course_3 ?
                            <div>
                                <Button variant="primary" className="my-3 d-flex justify-content-center" href={ROUTES.COURSE_3_CONTENT}>Zum Kurs</Button>
                            </div>
                            :
                            <div>
                                <Button variant="light" href="https://lisastraub.com/seminare/fuelle-pur" className="my-3 d-flex justify-content-center">Mehr erfahren</Button>
                            </div>
                        }
                    </Card.Body>
                </Card>
            </div>
        );
    }
}


const condition = authUser => !!authUser;


export default compose(
    withAuthentication,
    withFirebase,
    withEmailVerification,
    withAuthorization(condition),
)(DashboardCardCourse3);

